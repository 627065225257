<template>
  <v-list
    class="pt-0"
    dense
  >
    <v-subheader
      class="primary subtitle-2"
      dark
    >
      <span class="u-text-overflow">{{ item.type }}</span>
    </v-subheader>

    <v-list-item
      @click="updateStatus"
      class="mt-2"
      link
    >
      <v-list-item-icon>
        <v-icon>
          {{ item.status ? 'fas fa-times-circle' : 'fas fa-check' }}
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{ item.status ? 'Desativar' : 'Ativar' }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <!-- <v-list-item
      @click="deleteProfile"
      class="mt-2"
      link
    >
      <v-list-item-icon>
        <v-icon color="error">fas fa-trash</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="error--text"> Excluir </v-list-item-title>
      </v-list-item-content>
    </v-list-item> -->
  </v-list>
</template>

<script>
import { contextMenu } from '@/main.js'
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    updateStatus() {
      this.$api
        .patch(`profiles/status/${this.item.id}`, {
          status: !this.item.status,
        })
        .then(() => {
          contextMenu.action()

          this.item.status = !this.item.status
        })
        .catch(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Ops!',
            caption: 'Falha ao tentar atualizar o status deste perfil',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
    },
    deleteProfile() {
      this.$store.commit('setModal', {
        show: true,
        component: 'warning',
        text: 'Atenção!',
        caption: 'Deseja mesmo deletar este perfil?',
        confirm: () => {
          this.$api
            .delete(`profiles/${this.item.id}`)
            .then(() => {
              this.$store.commit('setModal', {
                show: true,
                component: 'success',
                text: 'Perfil excluido com sucesso',
                confirm: () => {
                  this.$store.commit('setModal', {
                    show: false,
                  })
                  contextMenu.action()
                },
              })
            })
            .catch(() => {
              this.$store.commit('setModal', {
                show: true,
                component: 'error',
                text: 'Ops! Falha ao tentar excluir este perfil',
                confirm: () => {
                  this.$store.commit('setModal', {
                    show: false,
                  })
                },
              })
            })
        },
        negate: () => {
          this.$store.commit('setModal', {
            show: false,
          })
        },
      })
    },
  },
}
</script>